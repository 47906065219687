/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

import { updateRouterHistory } from './routerNavGuard'

// Composables
import { createRouter, createWebHistory } from 'vue-router/auto'
import { hasSubdomain } from '@/helpers/util'
import { useUserStore } from '@/store/user';

const router = createRouter({
    history: createWebHistory(import.meta.env.VITE_BASE_URL)
})

router.beforeEach((to, from, next) => {
    const userStore = useUserStore();
    if (userStore.user === undefined && to.path !== '/login') {
        next({ path: '/login' });
        return;
    }

    if (!hasSubdomain() && to.path !== '/') {
        next({ path: '/' });
    } else {
        updateRouterHistory(to, from, next);
    }
});

export default router
