import { createApp } from 'vue'
import { registerPlugins } from '@/plugins'
import { initKeycloak } from '@/helpers/keycloak';
import App from './App.vue'

(async () => {
    await initKeycloak();

    const app = createApp(App);
    registerPlugins(app);

    app.mount('#app');

    document.title = 'Klantenportaal';
})();
