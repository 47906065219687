export function triggerRipple($el) {
    let ev = new Event("mousedown")
    let offset = $el.getBoundingClientRect()
    ev.clientX = offset.left + offset.width/2
    ev.clientY = offset.top + offset.height/2
    $el.dispatchEvent(ev)

    setTimeout(function() {
      $el.dispatchEvent(new Event("mouseup"))
    }, 300)
}

export function roundTo2Decimals(num){
  return +(Math.round(num + "e+2")  + "e-2");
}

//#region Subdomains

export function getSubdomain(){
  // Return a default subdomain on localhost
  if(import.meta.env.VITE_NAME === 'local'){
    return import.meta.env.VITE_SUBDOMAIN;
  }
  let host = window.location.host;
  return host.split('.')[0];
}

export function hasSubdomain(){
  // Using subdomains on localhost doesn't work so just pretend it does
  if(import.meta.env.VITE_NAME === 'local'){
    return true;
  }
  let host = window.location.host;
  let hostParts = host.split(import.meta.env.VITE_DOMAIN);

  return hostParts.length == 2 && hostParts[0] != "";
}

//#endregion

export function handleDownload(fileName, data){
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export const FileTypes = {
  ArrayBuffer: 0,
  DataURL: 1,
  Text: 2
}

export function readUploadedFile(inputFile, fileType = FileTypes.Text) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    switch(fileType){
      case FileTypes.ArrayBuffer:
        temporaryFileReader.readAsArrayBuffer(inputFile);
        break;
      case FileTypes.DataURL:
        temporaryFileReader.readAsDataURL(inputFile);
        break;
      case FileTypes.Text:
        temporaryFileReader.readAsText(inputFile);
        break;
    }
  });
};

export function toDisplayDateFormat(date){
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString('default', { month: 'short' });
  const year = dateObj.getFullYear();
  return day + ' ' + month + ' ' + year;
}