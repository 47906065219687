import { useRouterStore } from '@/store/router'


export const updateRouterHistory = async (to, from, next) => {
    const routerStore = useRouterStore();
    const { pushRoute } = routerStore;
    pushRoute(to);

    next();
}
