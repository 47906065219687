import { getApiRoot } from '@/helpers/api'
import { getSubdomain, hasSubdomain } from '@/helpers/util';
import axios from 'axios';
import { createI18n } from "vue-i18n";
import nl from './nl.json'
import fr from './fr.json'
import en from './en.json'


let texts = {
    nl: {},
    en: {},
    fr: {}
}
// if(hasSubdomain()){
//     try{
//       const response = await axios.get(
//         getApiRoot() + '/portal/texts', {
//           params: {
//             subDomain: getSubdomain()
//           }
//         }
//       );
//       if(response.status == 200){
//         texts = response.data.data;
//       }
//     }catch(err){
//       console.log(err)
//     }
// }

export default createI18n({
    locale: import.meta.env.VITE_DEFAULT_LOCALE,
    fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
    legacy: false,
    globalInjection: true,
    messages: {
        nl,
        fr,
        en
    }
})