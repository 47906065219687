import { defineStore } from 'pinia'
import { ref } from 'vue'
import apiClient from '@plugins/api-client'
import { getApiRoot } from '@helpers/api'
import { getKeycloak } from '@/helpers/keycloak';

export const InspectionStatuses = {
    Requested: 0,
    Approved: 1,
    Rejected: 2,
    Planned: 3
}

export const NetOperatorStatuses = {
    Requested: 0,
    Completed: 1
}

export const useInspectionStore = defineStore('inspectionStore', () => {
    const inspection = ref();

    async function getInspectionByID(id, source){
        let url = getApiRoot();
        switch(source) {
            case 'inspection':
                url += `/inspections/${id}`;
                break;
            case 'order':
                url += `/inspections/orders/${id}`;
                break;
            case 'invoice':
                url += `/inspections/invoices/${id}`;
                break;
        };
        try{
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.get(url, { headers });
            if(response.data.errors.length == 0){
                inspection.value = responseToInspection(response.data.data, source);
             }
             else{
                 throw new Error(response.data.errors)
             }
        } catch(error){
            throw new Error(error);        
        }
    }

    function responseToInspection(responseInspection, source) {
        let inspection = {};
        switch(source) {
            case 'inspection':
                inspection = responseInspection;
                break;
            case 'order':
            case 'invoice':
                inspection.keurderJobId = responseInspection.keurderJobId;
                inspection.inspectionDate = responseInspection.keuringAppointmentDate;
                inspection.approvedDate = responseInspection.approvedDate;

                inspection.status = InspectionStatuses.Requested;
                if(responseInspection.askedKeuring){
                    inspection.status = InspectionStatuses.Requested;
                }
                if(responseInspection.keuringAppointment) {
                    inspection.status = InspectionStatuses.Planned;
                }
                if(responseInspection.approved) {
                    inspection.status = InspectionStatuses.Approved;
                }
                inspection.articles = responseInspection.articles;
                break;
        };
        return inspection;
    }


    return {
        inspection,
        getInspectionByID
    };
})
