import { defineStore } from 'pinia'
import { ref } from 'vue'
import apiClient from '@plugins/api-client'
import { getApiRoot } from '@/helpers/api'
import { getKeycloak } from '@/helpers/keycloak';

export const InvoiceStatuses = {
    Paid: 1,
    Open: 0
}

export const useInvoiceStore = defineStore('invoiceStore', () => {
    const invoices = ref([]);
    const invoice = ref();

    async function getInvoiceAndRelatedInvoicesByID(id){
        invoices.value = [];
        try{
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.get(
                `${getApiRoot()}/invoices/${id}/related`,
                { headers }
            )
            if(response.data.errors.length == 0){
                let result = response.data.data;
                invoices.value.push({
                    invoiceId: id,
                    currentStatusId: result.invoice.paymentComplete,
                    isPrepaymentInvoice: false,
                })
                result.relatedInvoices.forEach(relatedInvoice => {
                    invoices.value.push({
                        invoiceId: relatedInvoice.invoiceId,
                        currentStatusId: relatedInvoice.paymentComplete,
                        isPrepaymentInvoice: relatedInvoice.isPrepaymentInvoice
                    })
                })
            }
            else{
                throw new Error(response.data.errors)
            }
        } catch(error){
            throw new Error(error);
        }
    }

    async function getInvoiceByID(id){
        try{
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await apiClient.get(
                `${getApiRoot()}/invoices/${id}`,
                { headers }
            )
            if(response.data.errors.length == 0){
                invoice.value = response.data.data;
            }
            else{
                throw new Error(response.data.errors)
            }
        } catch(error){
            throw new Error(error);
        }
    }

    return {
        invoice,
        invoices,
        getInvoiceAndRelatedInvoicesByID,
        getInvoiceByID
    }
  })
